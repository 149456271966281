import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link as LinkNav,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useRouter } from "next/navigation";
import { useSession, signOut } from "next-auth/react";
import { AuthService } from "@/auth/auth.service";
import { useAppState } from "store/hookstate/app.state";
import Link from "next/link";
import { app } from "@/config/app";
import dynamic from "next/dynamic";
import { mister_coding_logo2 } from "@/utils/image";
import useDarkmode from "@/hooks/userDarkmode";
import SearchArticleKelas from "@/features/search/SearchArticleKelas";

// const SearchArticleKelas = dynamic(
//   () => import("@/features/search/SearchArticleKelas"),
//   {
//     ssr: false,
//   }
// );

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();

  const router = useRouter();

  const session = useSession();

  const authService = new AuthService();

  const appState = useAppState();

  const { darkmode, setDarkMode } = useDarkmode();

  const handleChangeDarkMode = () => {
    if (darkmode == true) {
      setDarkMode(false);
    } else {
      setDarkMode(true);
    }
  };
  return (
    <>
      <Box sx={{ position: "sticky", top: "0" }} zIndex={1000} bg={"gray.50"}>
        <Flex
          // bg={darkmode == true ? "gray.900" : useColorModeValue("white", "gray.800")}

          color={useColorModeValue("gray.600", "white")}
          minH={"80px"}
          py={{ base: 2 }}
          px={{ sm: 100, base: 4 }}
          maxW={{ sm: "8xl", base: "6xl" }}
          mx={"auto"}
          // borderBottom={1}
          // borderStyle={"solid"}
          // borderColor={darkmode == true ? "gray.900" : useColorModeValue("gray.50", "gray.50")}
          align={"center"}
          pos="relative"

          // shadow={"sm"}
        >
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "center", md: "start" }}
            alignItems={{ base: "center" }}
          >
            <Image
              alt="Mister Coding"
              className="cursor-pointer"
              onClick={() => {
                router.replace("/");
              }}
              opacity={0.9}
              height={{ base: "auto", sm: 10 }}
              src={mister_coding_logo2}
            />

            <Flex display={{ base: "none", md: "flex" }} ml={3}>
              <DesktopNav />
            </Flex>
            <Box display={{ sm: "block", base: "none" }}>
              <SearchArticleKelas />
            </Box>
          </Flex>

          {session.status == "authenticated" ? (
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
              alignItems={"center"}
            >
              <Menu>
                <MenuButton
                  bg={"transparent"}
                  _active={{ bg: "transparent" }}
                  _hover={{ bg: "transparent" }}
                  _focus={{ bg: "transparent" }}
                  as={Button}
                  color={darkmode == true ? "gray.100" : ""}
                  rightIcon={<ChevronDownIcon />}
                >
                  Profile
                </MenuButton>
                <MenuList>
                  {/* <MenuItem as={"a"} href={"/member/account"}>Akun</MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      document.location = app.app_course_url;
                      // router.replace(app.app_course_url);
                    }}
                  >
                    Dashboards
                  </MenuItem>
                  {/* <MenuItem
                  onClick={() => {
                    router.replace(app.app_course_url+"/member/certificate");
                  }}
                >
                  Certificate
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    router.replace(app.app_course_url+"/member/account");
                  }}
                >
                  Akun
                </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      authService.logout();
                      appState.setLoading(true);
                      signOut({ redirect: true });
                    }}
                  >
                    Sign Out
                  </MenuItem>
                </MenuList>
              </Menu>
              <Button
                display={{ base: "none", md: "inline-flex" }}
                // borderRadius={{ base: 40 }}
                onClick={() => {
                  authService.logout();
                  appState.setLoading(true);
                  signOut({ redirect: true });
                }}
                px={{ base: 8 }}
                fontSize={"sm"}
                fontWeight={600}
                color={darkmode == true ? "gray.600" : "white"}
                bg={darkmode == true ? "gray.100" : "brand.500"}
                _hover={{
                  opacity: 0.9,
                }}
                rounded={"xl"}
              >
                Sign Out
              </Button>
              {/* <Icon onClick={handleChangeDarkMode} cursor={"pointer"} color={darkmode == true ? "yellow.400" : "gray.600"} fontSize={"2xl"} as={darkmode == true ? BsSun : MdOutlineDarkMode} /> */}
            </Stack>
          ) : null}

          {session.status == "unauthenticated" ? (
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
              alignItems={"center"}
            >
              <Button
                px={{ base: 4,sm:8 }}
                fontSize={"sm"}
                fontWeight={600}
                color={darkmode == true ? "gray.600" : "white"}
                bg={darkmode == true ? "gray.100" : "brand.500"}
                display={{ base: "block", md: "inline-flex" }}
                rounded={"xl"}
                _hover={{
                  opacity: 0.9,
                }}
                onClick={() => {
                  router.push("/auth/signin");
                }}
              >
                Masuk
              </Button>
              <Button
                onClick={() => {
                  router.push("/auth/sign-up");
                }}
                px={{ base: 8 }}
                fontSize={"sm"}
                fontWeight={600}
                color={darkmode == true ? "gray.600" : "white"}
                bg={darkmode == true ? "gray.100" : "brand.500"}
                display={{ base: "none", md: "inline-flex" }}
                rounded={"xl"}
                _hover={{
                  opacity: 0.9,
                }}
              >
                Daftar
              </Button>
              {/* <Icon onClick={handleChangeDarkMode} cursor={"pointer"} color={darkmode == true ? "yellow.400" : "gray.600"} fontSize={"2xl"} as={darkmode == true ? BsSun : MdOutlineDarkMode} /> */}
            </Stack>
          ) : null}
        </Flex>
      </Box>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
        <Box display={{ sm: "none", base: "block" }} bg={"white"} py={4}>
          <SearchArticleKelas />
        </Box>
      </Collapse>
    </>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const { darkmode } = useDarkmode();

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <LinkNav
                p={2}
                as={Link}
                href={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={darkmode == true ? "gray.100" : "gray.600"}
                _hover={{
                  textDecoration: "none",
                  color: darkmode == true ? "gray.400" : linkHoverColor,
                }}
              >
                {navItem.label}
              </LinkNav>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <LinkNav
      href={href}
      as={Link}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </LinkNav>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <LinkNav as={Link} key={child.label} py={2} href={child.href}>
                {child.label}
              </LinkNav>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Promo",
    href: "/promo",
  },
  {
    label: "Kelas",
    children: [
      {
        label: "Online Course",
        subLabel: "Belajar melalui video, teks, source code",
        href: "/kelas?category=all",
      },
      {
        label: "Bootcamp",
        subLabel: "Belajar intensif bareng mentor expert selama 1-2 bulan",
        href: "/bootcamp?category=all",
      },
    ],
  },
  // {
  //   label: "Kelas",
  //   href: "/kelas?category=all",
  // },
  {
    label: "Explore",
    children: [
      {
        label: "Leaderboard",
        subLabel: "Papan peringkat belajar",
        href: "/leaderboard",
      },
      {
        label: "Mentoring",
        subLabel: "Mentoring live session dengan Koin",
        href: "/koin",
      },
      {
        label: "Resources",
        subLabel: "Free & Premium",
        href: "https://sourcelibs.com/",
      },
      {
        label: "Join Mentor",
        subLabel: "Berbagung sebagai mentor",
        href: "/jadimentor",
      },
      {
        label: "Roadmap",
        subLabel: "Roadmap Developer",
        href: "/roadmap",
      },
      {
        label: "Events",
        href: "/events",
      },
      {
        label: "Playground",
        href: "/playground/learn",
      },
      {
        label: "Exercise",
        href: "/playground/exercise",
      },
      {
        label: "Compilers",
        href: "/playground/compiler",
      },
    ],
  },
  {
    label: "Posts",
    children: [
      {
        label: "Tutorial & Artikel",
        subLabel: "Tutorial & Artikel lengkap",
        href: "/post",
      },
      // {
      //   label: "Sharing",
      //   subLabel: "Cari artikel dari pengalaman member",
      //   href: "/sharing",
      // },
      {
        label: "Tutorial Set",
        subLabel: "Tutorial dengan urutan yang lengkap",
        href: "/post-set",
      },
    ],
  },
  // {
  //   label: "Tutorial & Artikel",
  //   href: "/post",
  // },
  // {
  //   label: "Tutorial Set",
  //   href: "/post-set",
  // },
];
